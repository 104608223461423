
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(20);
        App.SetWow(200);

        $('html').addClass('loaded');

        //toggle table
        $( "table" ).click(function() {
            $(this).find('tbody').toggle( "slow" );
        })

        //custom carousel
        $('[data-component=bannerProjeto]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 3,
                lazyLoad        : true,
                autoplay        : true,
                autoplayTimeout : $(this).data("autoplay-timeout"),
                nav             : true,
                animateOut      : 'fadeOut',
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : true,
                margin          : 16,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                    },
                    768:{
                        items:3,
                    }
                }

            });
        });

        //hide images carousel
        $('.tab').click(function(event){
            $('.tab').removeClass('_active');
            $(this).addClass('_active');
            event.preventDefault();
            var tab = $(this).attr('href');
            $('.banner-projeto').addClass('_hide');
            $('.'+tab).removeClass('_hide');
        });

        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .ok').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Open videos popup
        $('.open-video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // Close menu mobile on click
        $('.Header .menu nav ul li').click(function(event) {
            $('body').removeClass('_toggled');
        });
    });
});